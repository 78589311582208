import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = [ "my" ];

  mountTable(service) {
    $(this.myTarget).DataTable({
      info: true,
      destroy: true, //alow table be remounted
      processing: true, //utilize backend
      serverSide: true, //utilize backend
      ajax: {
        url: service
      },
      columns: [
        { width: "16%", name: 'dt_cad' },
        { width: "28%", name: 'patient' },
        { width: "12%", name: 'tipo_ex' },
        { width: "10%", name: 'resultado' },
        { width: "28%", name: 'posto_notif' },
        { width: "3%", searchable: false, orderable: false },
        { width: "3%", searchable: false, orderable: false },
        { width: "3%", searchable: false, orderable: false }
      ],
      order: [[0, 'asc']],
      pageLength: 25,
      lengthMenu: [[10,25,50,100,150,200,-1],[10,25,50,100,150,200,'Todos']],
      pagingType: "full_numbers",
      language: {
        search: "Procurar",
        lengthMenu: "Exibir _MENU_ items por página",
        zeroRecords: "Nada Encontrado",
        info: "Filtrados _MAX_ items",
        infoEmpty: "Nenhum item disponível",
        decimal: ",",
        thousands: ".",
        paginate: {
          first: "Primeira",
          previous: "Anterior",
          next: "Próxima",
          last: "Última"
        },
      }
    })
  }

  disconnect() {
    $(this.myTarget).DataTable().destroy();
  }

  connect() {
    console.log('START DATA TABLE');
    const urlCollection = this.data.get('collection');
    const service = `/${urlCollection}`;

    this.mountTable(service);
  }

}

