import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['options'];

  change(e) {
    $(this.optionsTarget).toggle();
  }

  connect() {
    console.log('START VIEW REPORT');
    $(this.optionsTarget).hide();
  }
}