import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dt" ]

  connect() {
    console.log('START DATEPICKER')

    $(this.dtTarget).flatpickr({
      "locale": "pt",
      static: true,
      allowInput: true,
      dateFormat: "d/m/Y",
    });
  }

}
