import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START UPLOADER')
  }

  submit(e) {
    e.preventDefault()
    e.stopPropagation()

    const url = this.data.get('url')
    const method = this.data.get('method')

    const form = document.querySelector('.form-uploader')
    const formData = new FormData(form)

    $.ajax({
      url,
      type: method || "PATCH",
      data: formData,
      processData: false,
      contentType: false
    })

  }
}
