import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'mybox' ]

  connect() {
    console.log('START SELECTBOX')
    this.maximumSelect = parseInt(this.element.getAttribute("data-maximum-select"))
    let options

    if(isNaN(this.maximumSelect)){
      options = {}
    } else {
      options = { maximumSelectionLength: this.maximumSelect }
    }

    $(this.myboxTarget).select2(options)
  }

  selectAllElements(e) {
    if($(e.target).is(':checked') ){
      $('option', this.myboxTarget).prop('selected','true')
    }else{
      $(this.myboxTarget).val(null)
    }
    $(this.myboxTarget).trigger('change')
  }

}
