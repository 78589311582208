import { Controller } from "stimulus"
import NProgress from 'nprogress'

export default class extends Controller {
  static targets = []

  startProgess(e) {
    console.log('start progress');
    NProgress.start();
  }

  stopProgress(e) {
    console.log('done progress');
    NProgress.done();
    NProgress.remove();
  }

  connect() {
    console.log('START PROGRESS');

    const splashHtml =
      `<div class="splash card-splash">
        <div role="spinner">
            <div class="spinner-icon"></div>
        </div>
        <p class="lead" style="text-align:center">Aguarde...</p>
        <div class="progress">
            <div class="mybar" role="bar">
        </div>
        </div>
    </div>`;

    NProgress.configure({
      template: splashHtml,
    });

    document.addEventListener('turbolinks:visit', this.startProgess.bind(this));
    document.addEventListener('turbolinks:render', this.stopProgress.bind(this));
    document.addEventListener('ajax:send', this.startProgess.bind(this));
    document.addEventListener('ajax:complete', this.stopProgress.bind(this));
  }

  disconnect() {
    document.removeEventListener('turbolinks:visit', this.startProgess.bind(this));
    document.removeEventListener('turbolinks:render', this.stopProgress.bind(this));
    document.removeEventListener('ajax:send', this.startProgess.bind(this));
    document.removeEventListener('ajax:complete', this.stopProgress.bind(this));
  }

}