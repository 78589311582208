import $ from 'jquery'
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import moment from 'moment'
import Chartkick from 'chartkick'
import Chart from 'chart.js'
import flatPickr from 'flatpickr'
import { Portuguese } from 'flatpickr/dist/l10n/pt'

//import 'cocoon-js'
//import 'select2'
//import 'select2/select2_locale_pt-BR'
//import 'qtip2'
import 'bootstrap'
import 'datatables.net'
import 'datatables.net-bs4'
import 'controllers'

Rails.start()
Turbolinks.start()

window.moment = moment
window.Chartkick = Chartkick
Chartkick.addAdapter(Chart)

import 'stylesheets/application'
