import { Controller } from "stimulus";

function mountFormData(entries) {
  const formData = new FormData();
  entries.forEach(entry => formData.append(entry[0], entry[1]));
  return formData;
}

function downloadBlob(blob, fileName) {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(a.href);
}

async function handleResponse(response) {
  try {
    const format = response.headers.get('Content-Type');
    
    if (/text\/html/.test(format)) {
      const body = await response.text();
      const $html = $(body).find('.card');
      $('.container').html($html);
      eval($("#placeholder script").text()); // executa o script embutido na div placeholder
    
    } else if (/application\/pdf/.test(format)) {
      const blob = await response.blob();
      downloadBlob(blob, 'fichas-report.pdf');
    
    } else if (/application\/vnd/.test(format)) {
      const blob = await response.blob();
      downloadBlob(blob, 'fichas-report.xlsx');
    }
    
    $('.modal-report').modal('hide');

  } catch (err) {
    console.log(err);
  }
}

export default class extends Controller {
  static targets = [ 'form', 'button' ];

  create(e) {
    e.preventDefault();
    this.buttonTarget.disabled = true;
    this.buttonTarget.textContent = 'Aguarde...';

    const entries = [...new FormData(this.formTarget).entries()];

    fetch('/cards/report', {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      },
      credentials: 'same-origin',
      body: mountFormData(entries),
    })
    .then(response => { 
      if (response.ok) {
        handleResponse(response); 
      } else {
        console.log('response != 200');
      } 
      this.buttonTarget.disabled = false;
      this.buttonTarget.textContent = 'Gerar Relatório';
    })
    .catch(err => {
      console.log('error', err);
      this.buttonTarget.disabled = false;
      this.buttonTarget.textContent = 'Gerar Relatório';
    });
  }

  connect() {
    console.log('START REPORT');
  }
}



