import { Controller } from "stimulus"
import Turbolinks from 'turbolinks'

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START GET FORM')
  }

  submit(event) {
    event.preventDefault()
    const entries = [...new FormData(event.target).entries()]
    const actionUrl = new URL(event.target.action)
    const currentUrl = new URL(location.href)
    
    // if pathname not changed, hand over per parameter to next page.
    if (actionUrl.pathname === currentUrl.pathname && currentUrl.searchParams.has('per')) {
      actionUrl.searchParams.set('per', currentUrl.searchParams.get('per'))
    }

    entries.forEach(entry => actionUrl.searchParams.set(...entry))
    Turbolinks.visit(actionUrl.toString())
  }

}
