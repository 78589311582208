import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START TABLE')
    $('tbody').hide()

    $('a[rel=popover]').popover({
      html: true,
      trigger: 'hover'
    })
  }

  showDetails(e) {
    let id = $(e.target).attr('id')
    $(`tbody.${id}`).toggle()
  }

}
