import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['age', 'dt'];

  change(e) {
    const currentYear = new Date().getFullYear();
    const dtBirth = moment(this.dtTarget.value, "DD/MM/YYYY").toDate();
    this.ageTarget.value = currentYear - dtBirth.getFullYear();
  }

  connect() {
    console.log('START BIRTH');
  }
}